module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yerai Alonso - Street Workout & Calistenia","short_name":"Yerai Alonso","description":"Atleta de Calistenia y Street Workout, con más de 6 años de experiencia, creador de uno de los canales de YouTube más influyentes en el sector así como de la aplicación de calistenia más reconocida. Autor del libro “Guía Completa de Calistenia y Street Workout”, profesor de cursos de calistenia nivel nacional, jurado de competiciones de calistenia a nivel nacional e internacional, doble campeón de Canarias y competidor a nivel nacional.","start_url":"/","background_color":"#222b2f","theme_color":"#222b2f","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
